import React from 'react';
import styled from 'styled-components';

import PT_LOGO from 'images/landing/kinedu-educators-pt.svg';
import ES_LOGO from 'images/landing/kinedu-educators-es.svg';
import EN_LOGO from 'images/landing/kinedu-educators-en.svg';

import EN_LOGO_WHITE from 'images/landing/bg-white-kinedu-educators-en.svg';
import PT_LOGO_WHITE from 'images/landing/bg-white-kinedu-educators-pt.svg';
import ES_LOGO_WHITE from 'images/landing/bg-white-kinedu-educators-es.svg';
import { PROJECT_PATH } from '../../../config';

const getLogo = (language, variant) => {
  const logos = {
    es: {
      default: ES_LOGO,
      white: ES_LOGO_WHITE,
    },
    en: {
      default: EN_LOGO,
      white: EN_LOGO_WHITE,
    },
    pt: {
      default: PT_LOGO,
      white: PT_LOGO_WHITE,
    },
  };

  const selectedVariant = logos[language] || logos.en;

  return selectedVariant[variant] || EN_LOGO;
};

const Logo = styled.img.attrs(() => ({
  alt: 'Kinedu Logo',
}))`
  width: 220px;
  height: 35px;
  cursor: pointer;
`;

const KineduLogo = ({
  lang = 'en',
  variant = 'default',
  className,
  ...props
}) => (
  <Logo
    className={className}
    src={getLogo(lang, variant)}
    onClick={() => window.open(PROJECT_PATH(), '_self')}
    {...props}
  />
);

export default KineduLogo;
