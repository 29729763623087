const size = {
  phone: '375px',
  largePhone: '480px',
  xLargePhone: '600px',
  tablet: '768px',
  largeTablet: '960px',
  desktop: '1024px',
  largeDesktop: '1400px',
  biggerScreens: '1920px',
};

const breakPoints = {
  phone: `@media (min-width: ${size.phone})`,
  largePhone: `@media (min-width: ${size.largePhone})`,
  xLargePhone: `@media (min-width: ${size.xLargePhone})`,
  tablet: `@media (min-width: ${size.tablet})`,
  largeTablet: `@media (min-width: ${size.largeTablet})`,
  desktop: `@media (min-width: ${size.desktop})`,
  largeDesktop: `@media (min-width: ${size.largeDesktop})`,
  biggerScreens: `@media (min-width: ${size.biggerScreens})`,
};

export { breakPoints, size };
