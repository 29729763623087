import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Button as Btn, KineduPalette } from 'kinedu-react-components';

const buttonColors = {
  primary: {
    main: '#73B84E',
    alt: 'white',
  },
  secondary: {
    main: '#0086D8',
    alt: 'white',
  },
  default: {
    main: KineduPalette.shadeLighter,
    alt: KineduPalette.shadeDark,
  },
  disabled: {
    main: KineduPalette.shadeMediumDark,
    alt: 'white',
  },
  undefined: {
    main: 'black',
    alt: 'transparent',
  },
};
const getColor = (color) => {
  switch (color) {
    case 'primary':
      return buttonColors.primary;
    case 'secondary':
      return buttonColors.secondary;
    case 'default':
      return buttonColors.default;
    case 'disabled':
      return buttonColors.disabled;
    default:
      return {
        main: color,
        alt: 'white',
      };
  }
};

const Button = styled(Btn).attrs(() => ({
  rounded: true,
  flat: true,
}))`
  min-height: 50px;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  font-size: 1.6rem;
  ${({ color, uppercased, dense }) => `
    ${dense ? 'min-height: 40px;' : ''}
    background-color: ${getColor(color).main};
    ${uppercased ? 'text-transform: uppercase;' : ''}
  `}
  transition: background-color 0.3s ease-in;
  ${({ disabled, shadowColored, color }) => shadowColored ? `
    ${disabled ? '' : `
      box-shadow: 0px 6px 8px 3px ${getColor(color).main}8c;
    `}
  ` : ''}
  &:hover {
    ${({ color }) => `
      background-color: ${getColor(color).main}d6;
    `}
  }

  position: relative;
  overflow: hidden;
  transition: all 400ms;

  span {
    width: 100%;
    height: 100px;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.7);
  }

  &:disabled {
    background-color: #A3A3A3;
  }
`;

const randomNumber = (max = 15) => Math.floor(Math.random() * max);

const randomId = () => {
  return `${randomNumber(500)}-${randomNumber(400)}-${randomNumber(1000)}`;
};

const ButtonComponent = ({
  children,
  id = `button-${randomId()}`,
  interval = null,
  uppercased = false,
  style,
  ...props
}) => {
  let timerRef = useRef();

  useEffect(() => {
    if (!interval) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timerRef = setInterval(() => {
      const button = document.querySelector(`#${id}`);

      if (button) {
        const span = button.querySelector('span');
        if (span) {
          span.classList.remove('ripple');
          span.classList.add('ripple');
          setTimeout(() => {
            span.classList.remove('ripple');
          }, interval - 1000);
        }
      }
    }, interval);

    return () => {
      if (interval && timerRef?.current) {
        clearTimeout(timerRef?.current);
      }
    };
  }, []);

  return (
    <Button style={style} uppercased={uppercased} id={id} {...props}>
      <span />
      {children}
    </Button>
  );
};

export default ButtonComponent;
